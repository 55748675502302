@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

* {
  font-family: 'Nunito Sans', sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --textColor: #fff;
}

body {
  color: var(--textColor);
}

h1 {
  font-size: 45px;
  font-weight: 700;
}

@media screen and (max-with: 480px) {
  h1 {
    font-size: 42px;
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(40%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
